import en from "./locales/en.json";
import zh_Hans from "./locales/zh_Hans.json";

export default defineI18nConfig(() => {
  return {
    legacy: false,
    messages: {
      en,
      zh_Hans,
    },
  };
});
